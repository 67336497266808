import React from "react";
import GlobalContextProvider from "./src/context/global-context-provider";
import * as Sentry from "@sentry/gatsby";
import "./src/style/global.css";

Sentry.init({
  dsn: "https://deb0bcea87a338d8c7e8c8fdd520bc80@o4505748831141888.ingest.sentry.io/4505753394151424",
  integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};
