exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-campaign-js": () => import("./../../../src/templates/campaign.js" /* webpackChunkName: "component---src-templates-campaign-js" */),
  "component---src-templates-clinic-js": () => import("./../../../src/templates/clinic.js" /* webpackChunkName: "component---src-templates-clinic-js" */),
  "component---src-templates-clinics-js": () => import("./../../../src/templates/clinics.js" /* webpackChunkName: "component---src-templates-clinics-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-treatment-js": () => import("./../../../src/templates/treatment.js" /* webpackChunkName: "component---src-templates-treatment-js" */)
}

